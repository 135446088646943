/**
 * @file Safe isomorphic getters for environment variables.
 *
 *  NOTE: This file is purposefully not imported to dotenv/index.js to avoid client scripts
 *  from including the server-side logic in that module. These getters + config should accessed
 *  via this module directly for safe isomorphic usage.
 */
const _get = require('lodash/get');
const _isPlainObject = require('lodash/isPlainObject');
const _isString = require('lodash/isString');
const _reduce = require('lodash/reduce');
const IS_CLIENT = typeof window !== 'undefined';

function log(message, level = 'ERROR') {
  console.log(`[service/dotenv][${level}] ${message}`);
}

function changeStringBoolToBool(val) {
  if (val === 'True' || val === 'true') {
    return true;
  } else if (val === 'False' || val === 'false') {
    return false;
  } else {
    return val;
  }
}

function formatEnvVars(envVar) {
  if (Array.isArray(envVar)) {
    return _reduce(
      envVar,
      (acc, value, index) => {
        acc[index] = formatEnvVars(value);

        return acc;
      },
      []
    );
  } else if (_isPlainObject(envVar)) {
    return _reduce(
      envVar,
      (acc, value, key) => {
        acc[key] = formatEnvVars(value);

        return acc;
      },
      {}
    );
  } else if (_isString(envVar) && envVar.split(',').length > 1) {
    return envVar.split(',');
  } else if (_isString(envVar)) {
    return changeStringBoolToBool(envVar);
  } else {
    return envVar;
  }
}

function getVariableValue(envObject, envVar) {
  if (envObject) {
    const variable = _get(envObject, envVar, envObject[envVar]);

    return variable !== undefined ? formatEnvVars(variable) : undefined;
  }

  return undefined;
}

class EnvVars {
  constructor() {
    this.allowedOnClient = [];
    this.allowedOnEditClient = [];
    this.getAllForWindow = this.getAllForWindow.bind(this);
  }

  getForClient(envVar, defaultValue = undefined) {
    const globalVariable = getVariableValue(global.env, envVar);
    const windowVariable = getVariableValue(window.env, envVar);

    if (globalVariable !== undefined) {
      return globalVariable;
    }

    if (windowVariable !== undefined) {
      return windowVariable;
    }

    return formatEnvVars(defaultValue);
  }

  getForServer(envVar, defaultValue = undefined) {
    const globalVariable = getVariableValue(global.env, envVar);
    const processVariable = getVariableValue(process.env, envVar);

    if (globalVariable !== undefined) {
      return globalVariable;
    }

    if (processVariable !== undefined) {
      return processVariable;
    }

    return formatEnvVars(defaultValue);
  }

  getAllForClient() {
    return formatEnvVars({
      ...window.env,
      ...global.env
    });
  }

  getAllForWindow(editMode) {
    ['allowedOnClient', 'allowedOnEditClient'].forEach((property) => {
      if (this[property] && !Array.isArray(this[property])) {
        log(`EnvVars.${property} must be an array of environment variable key strings`);
      }
    });

    let envVars = this.allowedOnClient;
    if (editMode) {
      envVars = envVars.concat(this.allowedOnEditClient);
    }

    return (
      envVars &&
      envVars.reduce((allowed, envVar) => {
        const value = this.getForServer(envVar);

        // TODO: Do we care about this being undefined if not forced from the props or dynamic load?
        // if (typeof value === 'undefined') {
        //   log('error', `${envVar} is currently allowed on the client but is not defined.`);
        // }

        allowed[envVar] = value;
        return allowed;
      }, {})
    );
  }

  getAllForServer() {
    return formatEnvVars({
      ...process.env,
      ...global.env
    });
  }
}

const env = new EnvVars();

/**
 * @description
 * Initialize amphora-env-vars with specific options.
 * Should be initialized before anything else in your app. Even express!
 *
 * @param {object} options
 * @param {string[]} options.allowedOnClient Whitelist of env var keys that can be accessed in client-side js modules.
 * @param {string[]} options.allowedOnEditClient Whitelist of env var keys that can be additionally accessed in client-side js in edit mode.
 */
function configure(options = {}) {
  env.allowedOnClient = options.allowedOnClient || [];
  env.allowedOnEditClient = options.allowedOnEditClient || [];
}

module.exports.configure = configure;
module.exports.get = IS_CLIENT ? env.getForClient : env.getForServer;
module.exports.getAll = IS_CLIENT ? env.getAllForClient : env.getAllForServer;
module.exports.getAllForWindow = IS_CLIENT ? env.getForClient : env.getAllForWindow;
