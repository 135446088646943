const EnvVars = require('../services/dotenv/variables');

/* global edition*/
/* eslint no-undef: "error"*/
function loadAdFuelBody() {
  if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableCep`)) {
    require('@cnnprivate/digital-ads-cep/dist/Cep.min.js');
  }

  if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableInViewRefresh`)) {
    require('@cnnprivate/digital-ads-inviewrefresh/dist/InViewRefresh.min.js');
  }

  if (
    EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableMalvertisingDetection`)
  ) {
    require('@cnnprivate/digital-ads-malvertisingdetection/dist/MalvertisingDetection.min.js');
  }

  if (EnvVars.get(`TRINITY_CONFIGURATION.${edition}.michonne.features.enableBlockThrough`)) {
    require('@cnnprivate/digital-ads-blockthrough/dist/Blockthrough.min.js');
  }

  require('@cnnprivate/digital-ads-adfuel/dist/adfuel.min.js');
}

(function () {
  if (window.AdFuelOptions) {
    loadAdFuelBody();
  } else {
    window.addEventListener('adfuel.loaded', loadAdFuelBody);
  }
})();
